<template>
    <div class="admin-content">
        <div class="main-wrapper">
            <el-scrollbar :native="false" class="help-wrapper">
                <div class="header">
                    <span class="title">{{helpDetail.title}}</span>
                    <span class="time">{{this.helpDetail.create}}</span>
                </div>
                <div class="help-content">
                    <div v-html="helpDetail.content"></div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StudentHelpDetail",
        data() {
            return {
                helpDetail: {}
            }
        },
        mounted() {
            this.getHelpDetail();
        },
        methods: {
            getHelpDetail() {
                this.$httpStudent.axiosGetBy(this.$api.help, {id: this.$route.query.id}, (res) => {
                    if (res.code === 200) {
                        this.helpDetail = res.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .admin-content {
        margin: auto;
    }
    .help-wrapper {
        height: 100%;
        background-color: #fff;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .main-wrapper {
        padding: 20px;
        background: transparent;
        .header {
            border-bottom: 1px solid #EEEEEE;
            padding: 35px 127px 20px;
            .title {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333;
                margin-right: 20px;
            }
            .time {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666;
            }
        }
        .help-content {
            padding: 20px 127px 20px;
        }
    }
</style>